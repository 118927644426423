import {NgModule} from '@angular/core';
import {Routes} from '@angular/router';
import {QuicklinkStrategy} from 'ngx-quicklink';

import {RouterModule} from '@shared/modules/router/router.module';

import {authInfoInitializedGuard} from './guards/auth-info-initialized.guard';
import {authGuard} from './guards/auth.guard';
import {USER_SETTINGS_ROUTES} from './pages/user-settings/user-settings.routes';
import {noAuthGuard} from './guards/no-auth.guard';
import {PageErrorComponent} from './pages/service-pages/page-error/page-error.component';

const routes: Routes = [
  {
    path: 'public/submit-success',
    loadComponent: () =>
      import('./pages/public/submit-success-page/submit-success-page.component').then(
        m => m.SubmitSuccessPageComponent,
      ),
  },
  {
    path: 'public/pages/:accessToken',
    loadComponent: () => import('./components/app-layout/app-layout.component').then(m => m.AppLayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/public/public-page/public-page.component').then(m => m.PublicPageComponent),
      },
    ],
  },
  {
    path: '',
    /**
     * Using `canActivate` guard here to delay pages init until `AuthUser` is ready.
     * This doesn't block route matching - requesting lazy JS chunk for matched route will be done
     * in parallel with `AuthUser` initialization request, but rendering of the route component will be blocked
     * until this guard finishes.
     */
    canActivate: [authInfoInitializedGuard],
    children: [
      {
        path: 'portal/sign-in',
        canActivate: [noAuthGuard],
        loadComponent: () =>
          import('./pages/sign-in/sign-in-page/sign-in-page.component').then(m => m.SignInPageComponent),
      },
      {
        path: 'portal/invitations/:id/:token',
        canActivate: [noAuthGuard],
        loadComponent: () =>
          import('./pages/accept-invitation/accept-invitation-page/accept-invitation-page.component').then(
            m => m.AcceptInvitationPageComponent,
          ),
      },
      {
        path: 'portal/password/new',
        canActivate: [noAuthGuard],
        loadComponent: () =>
          import('./pages/forgot-password/forgot-password-page/forgot-password-page.component').then(
            m => m.ForgotPasswordPageComponent,
          ),
      },
      {
        path: 'portal/password/reset/:id/:token',
        canActivate: [noAuthGuard],
        loadComponent: () =>
          import('./pages/reset-password/reset-password-page/reset-password-page.component').then(
            m => m.ResetPasswordPageComponent,
          ),
      },
      {
        path: 'portal/password/unlock/:id/:token',
        canActivate: [noAuthGuard],
        loadComponent: () =>
          import('./pages/unlock-password/unlock-password-page/unlock-password-page.component').then(
            m => m.UnlockPasswordPageComponent,
          ),
      },
      {
        path: '',
        loadComponent: () => import('./components/app-layout/app-layout.component').then(m => m.AppLayoutComponent),
        canActivateChild: [authGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'portal',
          },
          {
            // Service pages that don't change URL of the current page (using `skipLocationChange` option during navigation)
            path: '_ng_',
            children: [
              {
                path: 'page-load-error',
                component: PageErrorComponent,
              },
              {
                path: ':errorCode',
                component: PageErrorComponent,
              },
            ],
            data: {
              skipAuthGuard: true,
            },
          },
          {
            path: 'portal/settings',
            children: USER_SETTINGS_ROUTES,
          },
          {
            path: '',
            loadComponent: () =>
              import('./pages/apps-container/apps-container.component').then(m => m.AppsContainerComponent),
            children: [
              {
                path: 'portal',
                loadComponent: () =>
                  import('./pages/portal-dashboard/portal-dashboard.component').then(m => m.PortalDashboardComponent),
              },
              {
                path: ':appSlug',
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'tab',
                  },
                  {
                    path: 'tab',
                    loadComponent: () =>
                      import('./pages/app-dashboard/app-dashboard.component').then(m => m.AppDashboardComponent),
                    children: [
                      {
                        path: ':tabSlug',
                        loadComponent: () =>
                          import('./pages/app-dashboard/app-dashboard-tab/app-dashboard-tab.component').then(
                            m => m.AppDashboardTabComponent,
                          ),
                      },
                    ],
                  },
                  {
                    path: 'workflow-requests/:workflowRequestId',
                    loadComponent: () =>
                      import('./pages/workflow-request-page/workflow-request-page.component').then(
                        m => m.WorkflowRequestPageComponent,
                      ),
                  },
                  {
                    path: ':pageSlug/tasks/:taskId',
                    loadComponent: () =>
                      import('./pages/workflow-request-page/workflow-request-page.component').then(
                        m => m.WorkflowRequestPageComponent,
                      ),
                  },
                  {
                    path: ':pageSlug',
                    loadComponent: () => import('./pages/app-page/app-page.component').then(m => m.AppPageComponent),
                  },
                ],
              },
            ],
          },
          {
            path: '**',
            component: PageErrorComponent,
            data: {
              errorCode: '404',
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
