import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {captureException, init as initSentry} from '@sentry/angular';

import {config} from './config';
import {AppModule} from './app.module';

if (PRODUCTION) {
  initSentry({
    dsn: config.sentryDsn!,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    if (PRODUCTION) {
      captureException(err);
    } else {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  });
